
.padding-menu {
    padding-top: 72px;
}

.media-icons {
    height: 100%;
    vertical-align: middle;
}

.skills .skillset .level-bar-inner  {
    height: 15px;
    background: #e8e8e8 !important;
}

.progress {
    background: #66cb8c;
    height: 15px;
    animation: progressBar 3s ease-in-out;
    animation-fill-mode:both; 
}

@keyframes progressBar {
    0% { width: 0; }
    100% { width: 100%; }
}

.github-header-outer + div {
    height: 600px !important;
}